import React from "react"
import { DropDown, SortableTable, SizedImage, Places, FlowBlock, DotaPlusSubscribe, Loading, HeroImageTable, TalentImageTable } from "./samples"
import { R_subscribe, R_get, R_get_stats } from "../components/events"
import { localize } from "../components/localization"
import { one_to_percent, R_on_click_outside, copy_array_with_condition, background_style, getUserFrendlyHeroName } from "../components/utils"

const TOP_BLOCK_STYLE = `
	w-[12rem] h-full bg-gradient-to-t from-[#4e1252]
	to-[#4e1c77] border-r border-[#9923b0] last-child:border-none hover:brightness-125
	text-xl font-semibold text-shadow-xl
`

export const HeroesMetaTable: React.FC<{
	search: string
	talents: boolean
	period: TStatsPeriod
}> = props => {
	let heroes = R_get_stats<any[]>(`/api/heroes?talents=${props.talents ? "yes" : "no"}&period=${props.period}&pool=all`)

	if (heroes && props.search !== "") {
		heroes = copy_array_with_condition(
			heroes,
			hero =>
				getUserFrendlyHeroName(hero.heroName)
					.toLowerCase()
					.indexOf(props.search.toLowerCase())
				!== -1
		)
	}

	return (
		<div className="mx-auto">
			{heroes ? <SortableTable
				columns={[
					props.talents ? {
						title: "Hero",
						sortMethod: "unq",
						sortUnique: (a, b) => a.h.localeCompare(b.h),
						valueFunc: x =>
							<div className="flex items-center">
								<HeroImageTable heroName={x.h} />
								<TalentImageTable talentName={x.t} />
							</div>
					} : {
						title: "Hero",
						sortMethod: "alph",
						valueFunc: x => <HeroImageTable heroName={x} />
					},
					{ title: "Pickrate", sortMethod: "mtl", valueFunc: x => x === Infinity ? "—" : one_to_percent(x, 2) },
					{ title: "Total", sortMethod: "mtl", valueFunc: x => x === Infinity ? "—" : x },
					{ title: "Avg", sortMethod: "ltm" },
					{ title: "Places", valueFunc: x => <Places places={x} /> },
				]}
				rows={heroes.map(h => [
					props.talents
						? { h: h.heroName, t: (h as any).talentName }
						: h.heroName,
					h.pickrate || Infinity,
					h.matchCount || Infinity,
					Number(h.averagePlace).toFixed(2),
					h.places
				])}
				tableClassName="heroes-table"
				headCellClassName={sorted => `
					font-thin
					uppercase
					text-lg
					tracking-widest
					text-transparent
					bg-clip-text
					bg-gradient-to-b
					from-gray-100
					to-gray-500
					px-3
					${sorted ? `
						from-orange-500
						to-orange-600
						cursor-pointer
					` : `
						hover:from-white
						hover:to-gray-300
						cursor-pointer
					` }
				` }
				bodyRowStyle={background_style("images/bg-orange.png")}
				bodyRowClassName={`bg-[length:99%_60%] bg-no-repeat bg-center`}
				bodyCellClassName="text-center"
			/> : <Loading />}
		</div>
	)
}

export const HeroesMeta = () => {
	const [search, setSearch] = React.useState("")
	const periodDDRef = React.useRef<HTMLDivElement>(null)
	const [periodDD, setPeriodDD] = React.useState(false)
	const [period, setPeriod] = React.useState<TStatsPeriod>("four_days")
	const poolDDRef = React.useRef<HTMLDivElement>(null)
	const [poolDD, setPoolDD] = React.useState(false)
	const [pool, setPool] = React.useState<TStatsPool>("all")
	const [talents, setTalents] = React.useState<boolean>(false)
	const authorized = R_subscribe("authorized")

	R_subscribe("language")
	R_on_click_outside(periodDDRef, () => setPeriodDD(false))
	R_on_click_outside(poolDDRef, () => setPoolDD(false))

	return (
		<div className="min-h-[100vh] flex flex-col text-white">
			{authorized && authorized.dotaPlusExpireDate && authorized.dotaPlusExpireDate > Date.now() ?
				<>
					<div className="w-full h-16 shadow-custom flex border-b border-t border-[#27092c]">
						<div className={`${TOP_BLOCK_STYLE} flex-1 flex py-2 px-4`}>
							<div className="w-full bg-[#401049] p-2 rounded-md border border-[#27092c] shadow-inner-custom font-medium">
								<input
									className="h-full w-full"
									placeholder={localize("enter_hero_name")}
									onChange={event => setSearch(event.target.value)}
								/>
							</div>
						</div>
						<div className={TOP_BLOCK_STYLE} ref={periodDDRef}>
							<button className="w-full h-full" onClick={() => setPeriodDD(!periodDD)}>
								{localize(`stats_period_${period}`)}
							</button>
							<DropDown isOpen={periodDD}>
								{(["four_days", "prev_week"] as TStatsPeriod[]).map((p, i) =>
									<button key={i} onClick={() => setPeriod(p)}>{localize(`stats_period_${p}`)}</button>
								)}
							</DropDown>
						</div>
						<button className={`${TOP_BLOCK_STYLE} ${talents ? "shadow-inner-gold" : ""}`} onClick={() => setTalents(!talents)}>
							{localize("heroes_talents")}
						</button>
					</div>
					<HeroesMetaTable search={search} period={period} talents={talents} />
				</>
				:
				<div className="mt-12 flex flex-col items-center">
					<div className="mb-6 text-2xl">
						{localize("talens_stats_not_available")}
					</div>
					<DotaPlusSubscribe />
				</div>
			}
		</div>
	)
}