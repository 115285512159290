import React from "react"
import { SortableTable, SizedImage, Places, HeroImage_leaderboard, FlowBlock } from "./samples"
import { R_subscribe, R_get, R_get_stats } from "../components/events"
import { localize } from "../components/localization"
import { one_to_percent, R_on_click_outside } from "../components/utils"

export const Leaderboard = () => {
	const leaderboard = R_get_stats<ISiteLeaderboardRow[]>( `/api/leaderboard` )
	const authorized = R_subscribe( "authorized" )

	const medals = [0, 250, 500, 750, 1000, 1300]
	let medal = 1

	if ( authorized && authorized.authorized ) {
		for ( const i in medals ) {
			if ( medals[i] > authorized.rating ) {
				break
			}

			medal = Number( i ) + 1
		}
	}

	R_subscribe( "language" )

	return (
		<div className="flex flex-col text-white">
			<FlowBlock type="1" />
			<div className={ `
				w-full h-16 bg-gradient-to-t from-[#4e1252]
				to-[#4e1c77] border-r border-[#9923b0] last-child:border-none hover:brightness-125
				text-xl font-semibold text-shadow shadow-custom border-b border-t border-[#27092c] flex flex-col items-center
			` }>
				<div className="w-full h-full flex flex-row justify-between items-center">
					{ !authorized || !authorized.authorized ? 
						<div className="ml-4 leaderboard-local-rating leaderboard-login">
							{ localize( "leaderboard_login" ) }	
						</div>
					:
						<div className="ml-4 flex items-center leaderboard-local-rating">
							<div>
								{localize( "leaderboard_player_rating" ).replace( "%rating%", authorized.rating.toString() ) }
							</div>
							<SizedImage className="leaderboard-local-icon w-12 h-12" src={ `/images/medals/${ medal }.png` } />
						</div>
					}
					<div className="mr-4 leaderboard-local-rating">{ localize( "Season 4" ) }</div>
				</div>
			</div>
			<FlowBlock type="4" />
			{ leaderboard ?
				<table className="leaderboard-table">
					<thead>
						<tr>
							<td className="leaderboard-top">{ localize( "place" ) }</td>
							<td className="leaderboard-top">{ localize( "player" ) }</td>
							<td className="leaderboard-top">{ localize( "rating" ) }</td>
							<td className="leaderboard-top">{ localize( "leaderboard_match_count" ) }</td>
							<td className="leaderboard-top">{ localize( "favorite_hero" ) }</td>
						</tr>
					</thead>
					<tbody>
						{ leaderboard.map( (row,i)  =>
							<tr className="leaderboard-player-block">
								<td className="leaderboard-place">{ i + 1 }</td>
								<td>
									<a className="flex items-center" href={ row.steamUrl } target="_blank">
										<SizedImage className="w-12 h-12 leaderboard-icon rounded-md" src={ row.avatarUrl } />
										<div className="flex leaderboard-name">{ row.nickname }</div>
									</a>
								</td>
								<td className="leaderboard-rating">{ row.rating }</td>
								<td className="leaderboard-games">{ row.matchCount }</td>
								<td className='h-14 flex flex-col items-center'>
									<div className="leaderboard-hero h-14 flex items-center">
										<HeroImage_leaderboard heroName={ row.favoriteHero }/>
									</div>
								</td>
							</tr>
						 ) }
					</tbody>
				</table>
			: undefined }
		</div>
	)
}