import React from "react"
import { useState } from "react"
import { R_subscribe, R_get, R_get_stats } from "../components/events"
import { Link, useParams, Navigate } from "react-router-dom"
import { localize, getLanguage } from "../components/localization"
import { SizedImage, Loading } from "./samples";
import { get, set_state, get_state } from "../components/events"
import { getClearHeroName, getUserFrendlyHeroName } from "../components/utils"
import { ItemBuildContainer } from "./itemBuilds"
import { heroResources } from "./heroes"
import { Tooltip } from 'react-tooltip';

export const Hero = () => {
	const lang = getLanguage();
	const { name } = useParams();
	const heroFullName = 'npc_dota_hero_' + name;

	const isExists = heroResources.allHeroList.find(f => f == heroFullName);

	if (!isExists) {
		return <Navigate to='/heroes' />
	}

	const heroData = R_get<IHero>(`/shared/${lang}_${heroFullName}.json`);
	return heroData ? <HeroBlock heroName={name!} heroData={heroData} /> : <Loading />;
}

const HeroBlock: React.FC<{
	heroName: string
	heroData: IHero | undefined
}> = props => {

	const heroData = props.heroData;
	const heroName = props.heroName;
	const fullHeroName = 'npc_dota_hero_' + heroName;
	const userFrendlyHeroName = getUserFrendlyHeroName(fullHeroName);

	if (heroData === undefined)
		return <></>

	return <div className="ml-5 text-white">
		<div className="flex col mt-5">
			<SizedImage className="shadow-custom-xs w-[120px] h-[70px]" src={`/images/heroes/${fullHeroName}.png`} />
			<div className={`dota-plus-small ml-4 text-[#fedf84] text-4-5xl font-bold text-shadow`}>
				{userFrendlyHeroName}
			</div>
		</div>
		<BlockTemplate child={<TalentsBlock heroName={heroName} heroData={heroData} />} />
		<BlockTemplate child={<ItemBuildContainer heroName={fullHeroName} />} />
		<BlockTemplate child={<ChangesBlock heroName={heroName} changes={heroData.changes} />} />
		<Tooltip className='z-10 w-fit max-w-[30rem]' id='talent' />
	</div>
}

const TalentsBlock: React.FC<{
	heroName: string,
	heroData: IHero
}> = props => {

	const heroData = props.heroData;
	const name = props.heroName;
	const skills = Object.keys(heroData.orangeTalents);

	return <div className='flex flex-col min-w-fit h-fit space-y-4'>
		<div className="p-4 bordered">
			<div className="grid grid-cols-4 xl:grid-cols-4 xl:divide-x-2 xl:divide-y-0 divide-y-2 divide-gray-500 p-1 gap-y-10 ">
				{skills.map((skill, si) => {
					return (
						<div
							key={skill}
							className="flex flex-col pt-4 space-y-8 xl:px-5"
						>
							<div className="flex justify-center relative">
								<div
									style={{ filter: "blur(75px)" }}
									className="absolute inset-0 bg-orange-500"
								/>
								<SizedImage
									className="rounded-md border-2 border-orange-500 border-solid z-10 w-[80px] h-[80px]"
									src={`/shared/images/icons/mini/npc_dota_hero_${name}/${skill.toLowerCase()}_png.png`}
									alt=""
									imgClassName="rounded-md"
									dataTooltipHtml={heroData.orangeTalents[skill][0].description}
									dataTooltipId='talent'
								/>
							</div>
							<div className="flex relative mx-auto">
								<div
									style={{ filter: "blur(75px)" }}
									className="absolute inset-0 bg-purple-500"
								/>
								<div className="flex space-x-4 z-10">
									{heroData.purpleTalents[skill].map((t, i) => {
										return (
											<div key={i} data-tooltip-id='talent' data-tooltip-html={t.description} className="flex flex-col bg-black p-1 rounded-md border-2 border-purple-500 border-solid space-y-1">
												<SizedImage
													key={t.modName}
													className="rounded-md border-[1px] border-gray-600 border-solid w-[56px] h-[56px]"
													src={`/shared/images/icons/mini/npc_dota_hero_${name}/${t.image.toLowerCase()}_png.png`}
													alt=""
												/>
												<SizedImage
													className="w-[56px] h-[15px]"
													imgClassName="w-full"
													src={`/shared/images/epic_level_${t.levels}${t.levels}_png.png`}
													alt=""
												/>
											</div>
										);
									})}
								</div>
							</div>
							<div className="flex relative mx-auto">
								<div
									style={{ filter: "blur(75px)" }}
									className="absolute inset-0 bg-blue-500"
								/>
								<div className="flex space-x-4 z-10">
									{heroData.blueTalents[skill].map((t, i) => {
										return (
											<div key={i} data-tooltip-id='talent' data-tooltip-html={t.description} className="flex flex-col bg-black p-1 rounded-md border-2 border-blue-500 border-solid space-y-1">
												<SizedImage
													className="rounded-md border-[1px] border-gray-600 border-solid w-[56px] h-[56px]"
													src={`/shared/images/icons/mini/npc_dota_hero_${name}/${t.image.toLowerCase()}_png.png`}
													alt=""
												/>
												<SizedImage
													className="rounded-m w-[56px] h-[15px]"
													imgClassName="w-full"
													src={`/shared/images/blue_level_${t.levels}_png.png`}
													alt=""
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	</div>
}

const ChangesBlock: React.FC<{
	heroName: string,
	changes: IHeroChange[]
}> = props => {
	const changes = props.changes;
	const name = props.heroName;

	return <div className="h-fit p-4 bordered">
		<p className="font-bold text-white text-3xl">
			{localize("herochanges")}
		</p>
		<div className="flex flex-col space-y-4 pt-4">
			{changes.map((change) => {
				return (
					<div
						key={change.description}
						className="flex items-center space-x-3"
					>
						<SizedImage
							className="rounded-md w-[32px] h-[32px]"
							src={`/shared/images/icons/mini/npc_dota_hero_${name}/${change.name.toLowerCase()}_png.png`}
						/>
						<p dangerouslySetInnerHTML={{ __html: change.description }}></p>
					</div>
				);
			})}
		</div>
	</div>
}

const BlockTemplate: React.FC<{
	child: any,
	class?: string
}> = props => {
	return <div className={`mt-5 w-full rounded-md shadow-custom-xs ${props.class} bg-gray relative`}>
		{props.child}
	</div>
}