import React from "react"
import { R_subscribe, R_get, R_get_stats } from "../components/events"
import { Link, useParams } from "react-router-dom"
import { localize, getLanguage } from "../components/localization"
import { SizedImage, Loading } from "./samples";
import { get, set_state, get_state } from "../components/events"
import { getClearHeroName } from "../components/utils"

export let heroResources: IHeroesResources = {} as IHeroesResources;

const heroAttrImages = {
	["DOTA_ATTRIBUTE_STRENGTH"]: "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/icons/hero_strength.png",
	["DOTA_ATTRIBUTE_AGILITY"]: "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/icons/hero_agility.png",
	["DOTA_ATTRIBUTE_INTELLECT"]: "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/icons/hero_intelligence.png",
	["DOTA_ATTRIBUTE_ALL"]: "https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/icons/hero_universal.png",
} as Dictionary<string>

const heroAttrLocalize = {
	["DOTA_ATTRIBUTE_STRENGTH"]: "str",
	["DOTA_ATTRIBUTE_AGILITY"]: "agi",
	["DOTA_ATTRIBUTE_INTELLECT"]: "int",
	["DOTA_ATTRIBUTE_ALL"]: "all",
} as Dictionary<string>

export async function init_heroes_static() {
	const heroes = await get(`/shared/heroes.json`);
	const allHeroList = [];

	allHeroList.push(heroes["DOTA_ATTRIBUTE_STRENGTH"])
	allHeroList.push(heroes["DOTA_ATTRIBUTE_AGILITY"])
	allHeroList.push(heroes["DOTA_ATTRIBUTE_INTELLECT"])
	allHeroList.push(heroes["DOTA_ATTRIBUTE_ALL"]);

	var mergedHeroList = allHeroList.reduce(function (prev, next) {
		return prev.concat(next);
	});

	heroResources = {
		heroes: heroes,
		allHeroList: mergedHeroList
	};
}

export const HeroesList = () => {
	const lang = getLanguage();

	return <div className="flex flex-col ml-5">
		<HeroesRow heroAttrType="DOTA_ATTRIBUTE_STRENGTH" />
		<HeroesRow heroAttrType="DOTA_ATTRIBUTE_AGILITY" />
		<HeroesRow heroAttrType="DOTA_ATTRIBUTE_INTELLECT" />
		<HeroesRow heroAttrType="DOTA_ATTRIBUTE_ALL" />
	</div>
}

const HeroesRow: React.FC<{
	heroAttrType: "DOTA_ATTRIBUTE_STRENGTH" | "DOTA_ATTRIBUTE_INTELLECT" | "DOTA_ATTRIBUTE_AGILITY" | "DOTA_ATTRIBUTE_ALL"
}> = props => {
	const heroAttrType = props.heroAttrType;

	return <div className="mt-8">
		<div className="flex space-x-2 w-full pb-2 items-center">
			<SizedImage
				className="w-32px h-32px"
				src={`${heroAttrImages[heroAttrType]}`}
				alt=""
			/>
			<p className="text-gray-400 font-bold">{localize(heroAttrLocalize[heroAttrType])}</p>
		</div>
		<div className="grid grid-cols-3 xs:grid-cols-3 md:grid-cols-5 xl:grid-cols-8 w-auto gap-4">
			{heroResources.heroes[heroAttrType].sort().map((h, i) => (
				<HeroCard name={h} key={i} />
			))}
		</div>
	</div>
}

const HeroCard: React.FC<{ name: string }> = props => {
	return (
		<Link
			className="h-25 rounded-md border-solid border-2 border-slate-500"
			to={`/hero/${getClearHeroName(props.name, false)}`}>
			<div className="cursor-pointer hovering flex"
				style={{ height: "100%", width: "100%" }}>
				<img className="rounded-md brightness-[90%] hover:brightness-[150%]"
					style={{ objectFit: "cover" }}
					src={`https://cdn.cloudflare.steamstatic.com/apps/dota2/images/dota_react/heroes/${getClearHeroName(props.name, false)}.png`} />
			</div>
		</Link>
	);
}